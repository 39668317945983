import { defineStore } from 'pinia'

export const useCategoriesStore = defineStore('categoriesStore', () => {
  const available = ref([])

  const availableCategoryBySlug = slug => {
    let category = null
    if (available.value.length > 0) {
      const findNestedCategory = function (arr, parent, slug, nestingKey) {
        return arr?.some(item => {
          if (item.slug === slug) {
            category = item
            return true
          }
          if (item[nestingKey]) {
            findNestedCategory(item[nestingKey], item, slug, nestingKey)
          }
          return false
        })
      }
      findNestedCategory(available.value, null, slug, 'children')
    }
    return category
  }
  const availableCategoryByUid = uid => {
    const findNestedCategory = (arr, uid, nestingKey) => {
      return arr.reduce((a, item) => {
        if (a) return a
        if (item.uid === uid) return item
        if (item[nestingKey]) return findNestedCategory(item[nestingKey], uid, nestingKey)
        return null
      }, null)
    }
    const res = findNestedCategory(available.value, uid, 'children')
    return res
  }
  const findCategoryByUid = (categoryList, uid) => {
    const findNestedCategory = (arr, uid, nestingKey) => {
      return arr.reduce((a, item) => {
        if (a) return a
        if (item.uid === uid) return item
        if (item[nestingKey]) return findNestedCategory(item[nestingKey], uid, nestingKey)
        return null
      }, null)
    }
    const res = findNestedCategory(categoryList, uid, 'children')
    return res
  }
  const findAvailableCategoryByName = name => {
    const categories = []
    const normalizeStr = str => {
      return str?.normalize('NFD').replace(/[\u0300-\u036f]/gi, '')
    }
    let regExp = new RegExp(normalizeStr(name), 'gi')
    const findNestedCategory = (arr, name, nestingKey) => {
      return arr.forEach(item => {
        if (regExp.test(normalizeStr(item.name)) || regExp.test(normalizeStr(item.label))) {
          categories.push(item)
        }
        if (item[nestingKey]) return findNestedCategory(item[nestingKey], name, nestingKey)
      })
    }
    findNestedCategory(available.value, name, 'children')
    return categories
  }
  const findCategoryByName = (categoryList, name) => {
    const categories = []
    const normalizeStr = str => {
      return str?.normalize('NFD')?.replace(/[\u0300-\u036f]/gi, '')
    }
    let regExp = new RegExp(normalizeStr(name), 'gi')
    const findNestedCategory = (arr, name, nestingKey) => {
      for (let i = 0; i < arr?.length; i++) {
        const item = arr[i]
        if (regExp.test(normalizeStr(item.name)) || regExp.test(normalizeStr(item.label))) {
          categories.push(item)
        }
        if (item[nestingKey]) findNestedCategory(item[nestingKey], name, nestingKey)
      }
    }
    console.log(categoryList)
    findNestedCategory(categoryList, name, 'children')
    return categories
  }
  const getCategoryTree = categories => {
    const tree = new Map()
    const firstCatMap = new Map()

    available.value.forEach(firstCat => {
      const currentFirstCat = { ...firstCat, children: [] }
      firstCatMap.set(firstCat.uid, currentFirstCat)
      tree.set(firstCat.slug, currentFirstCat)
    })

    categories?.forEach(thirdCat => {
      available.value?.forEach(firstCat => {
        firstCat.children?.forEach(secondCat => {
          if (thirdCat.parent_uid === secondCat.uid) {
            const currentFirstCat = firstCatMap.get(firstCat.uid)
            let currentSecondCat = currentFirstCat.children?.find(child => child?.uid === secondCat?.uid)

            if (!currentSecondCat) {
              currentSecondCat = { ...secondCat, children: [] }
              currentFirstCat.children.push(currentSecondCat)
            }

            currentSecondCat.children.push(thirdCat)
          }
        })
      })
    })

    return Array.from(tree.values()).filter(x => x.children?.length)
  }

  const setAvailableCategories = categories => {
    available.value = categories
  }

  const getAvailableCategories = async () => {
    if (available.value.length > 0) return
    const { $api } = useNuxtApp()
    try {
      await $api()
        .category.getCategories([], true)
        .then(rawCategories => {
          const bindParents = function (arr, parent, nestingKey) {
            for (let i = 0; i < arr?.length; i++) {
              const item = arr[i]
              if (item[nestingKey]) {
                item.parent = parent ? { ...parent, children: null } : null
                bindParents(item[nestingKey], item, nestingKey)
              }
              return false
            }
          }
          bindParents(rawCategories, null, 'children')
          setAvailableCategories(rawCategories)
        })
    } catch (e) {
      console.log(e)
      throw createError({ statusCode: 404, message: e.message })
    }
  }

  return {
    available,
    findCategoryByUid,
    findAvailableCategoryByName,
    findCategoryByName,
    getCategoryTree,
    getAvailableCategories,
    availableCategoryByUid,
    availableCategoryBySlug,
    setAvailableCategories,
  }
})
